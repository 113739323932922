<!--  -->
<template>
  <div id="container">
   <div class="listweb">同享直播</div>
   <div class="listapp"> 北京东华万联科技有限公司  <span @click="webapp">京ICP备15005365号-7</span>   北京市朝阳区新天第大厦 010-67760432</div>
  </div>
</template>

<script>
import * as Three from 'three'
// 导入轨道控制器
import {
  OrbitControls
} from 'three/examples/jsm/controls/OrbitControls'
// import {RGBELoader} from 'three/examples/jsm/loaders/RGBELoader'
let scene = null,
camera=null,
renderer=null,
mesh=null
export default {
  data () {
    return {
        
    };
  },
  methods:{
    init(){
      let container = document.getElementById('container');

      // 添加相机
      camera = new Three.PerspectiveCamera(70, container.clientWidth/container.clientHeight, 0.01, 1000);
      // 相机的位置
      camera.position.z = 0.1

      // 场景
      scene = new Three.Scene()


      // 创建几何体
      // let geometry = new Three.BoxGeometry(10,10,10);
      // // 材质
      // // let a=new Three.TextureLoader().load(``)
        
      // // let material = new Three.MeshBasicMaterial({color:0x00ff00});
      // // 设置材质贴图纹理 左右，上下，前后
      // let ar=['1','2','3','5','6','7']
      // // 材质添加
      // let arrboxmesh=[]
      // ar.forEach((item)=>{
      //   // 纹理加载
      //   let texture=new Three.TextureLoader().load(`static/image/${item}.jpg`)
        
        
      //   if(item==='3'||item==='5'){
      //     texture.rotation=Math.PI
      //     arrboxmesh.push(new Three.MeshBasicMaterial({map:texture}))
      //   }else {
      //     arrboxmesh.push(new Three.MeshBasicMaterial({map:texture}))
      //   }
      //   // console.log(`../image/${item}.jpg`)
      // })
      // // 将几何体材质合成物体
      // console.log(arrboxmesh)
      
      // mesh = new Three.Mesh(geometry, arrboxmesh);
      // mesh.geometry.scale(1,1,-1)
      // scene.add(mesh);
      
        // 创建多边形

      // 创建球
      let geometry=new Three.SphereBufferGeometry(5,32,32)
       let a=new Three.TextureLoader().load(`static/image/8.jpg`)
        
      let material = new Three.MeshBasicMaterial({map: a });
      mesh = new Three.Mesh(geometry, material);
  mesh.geometry.scale(1, 1, -1);
  scene.add(mesh);
      // const loader = new RGBELoader();
//       loader.load("static/image/8.jpg", (texture) => {
//         console.log(texture)
//   const material = new Three.MeshBasicMaterial({ map: texture });
//    mesh = new Three.Mesh(geometry, material);
//   mesh.geometry.scale(1, 1, -1);
//   scene.add(mesh);
// });
      // mesh=Three.Mesh(geometry, loader)
      // scene.add(mesh)
// loader.load("static/image/8.jpg", (texture) => {
//   const material = new Three.MeshBasicMaterial({ map: texture });
//    mesh = new Three.Mesh(geometry, material);
//   mesh.geometry.scale(1, 1, -1);
//   scene.add(mesh);
// });
      
      // 初始化渲染器
      renderer = new Three.WebGLRenderer({antialias:true});
      // 渲染器的大小
      renderer.setSize(container.clientWidth,container.clientHeight);
      // 将渲染器添加到页面
      container.appendChild(renderer.domElement);



      // 创建轨道控制器 相机围绕模型看到的角度
      const OrbitControl = new OrbitControls(camera, renderer.domElement)
      // 设置轨道自然
      OrbitControl.enableDamping = true
      // 设置惯性
      OrbitControl.update()


    },
    animate(){
      // 浏览器自动渲染下一帧
      requestAnimationFrame(this.animate);
     
      // 渲染到页面
      renderer.render(scene,camera); 
    },
    webapp(){
      window.location.href='https://beian.miit.gov.cn/'
    }
  },
  mounted(){
      this.init()
      this.animate()
  }

}

</script>
<style scoped>
#container{
    width: 100vw;
    height: 100vh;
}
.listweb {
  position: fixed;
  top: 5px;
  left: 5px;
  font-size: 30px;
  font-weight: 900;
  color: aliceblue;
}
.listapp {
  position: fixed;
bottom: 5px;
/* right: 20%; */
text-align: center;
width: 100vw;
font-size: 16px;
color: red;
font-weight: 600;
}
</style>
