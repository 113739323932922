<!--  -->
<template>
  <div>
    <!-- 公司介绍 -->
    
   <div  id="container">
    <div class="introduce" @click="sandapp">公司介绍</div>
    <div class="listapp" > 医知医汇（北京）科技有限公司  <span class="todolist"  @click="webapp" style="color:blue"> 京ICP备2020048198号-2</span>    010-67760432 北京市朝阳区新天第大厦</div>
   </div>
  </div>
</template>

<script>
import * as Three from 'three'
// 导入轨道控制器
import {
  OrbitControls
} from 'three/examples/jsm/controls/OrbitControls'
// import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
// 引入模型加载
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
// 动画库
import gsap from 'gsap'
// 镜面
// 拿到角度
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
// import {Reflector} from 'three/examples/jsm/objects/Reflector'
// import {RGBELoader} from 'three/examples/jsm/loaders/RGBELoader'
let scene = null,
camera=null,
renderer=null,
mesh=null,
animationMixer=null,
clock=null,
loader=null,
// 第一个动画
action=null,
// 第二个动画
actions=null,
amtion=null,

// glbe=null,
// 判断位置是否一致
intersect=null,
// 摄像机位置
cameraverar=null
// 初始的坐标
// threelistZ=0,
// threelistX=-10
// glbe=null
export default {
  data () {
    return {
        // 结束时的坐标
        // stopX:'',
        // stopZ:'',
        // stoptime:'',
        // amtion:'',
        // time:''
    };
  },
  methods:{
    init(){
      let container = document.getElementById('container');
      // 添加相机
      camera = new Three.PerspectiveCamera(70, container.clientWidth/container.clientHeight, 0.01, 1000);
      // 相机的位置
      var vector = new Three.Vector3( 0, 0, - 1 );
      vector.applyQuaternion( camera.quaternion );
      // angle = vector.angleTo( target.position )
      console.log(vector)
      camera.position.z = -150
      camera.position.y=0
      camera.position.x=0
      console.log(vector,camera.position)
      // 场景
      scene = new Three.Scene()
      // 创建几何体 x y z
      let geometry = new Three.BoxGeometry(300,100,360);
      // 设置材质贴图纹理 左右，上下，前后
      let ar=['1','3','6','5','2','4']
      // 材质添加
      let arrboxmesh=[]
      ar.forEach((item)=>{
        // 纹理加载
        let texture=new Three.TextureLoader().load(`static/image/image/${item}.jpg`)
          arrboxmesh.push(new Three.MeshBasicMaterial({map:texture}))
      })
      // 将几何体材质合成物体
      console.log(arrboxmesh)
      
      mesh = new Three.Mesh(geometry, arrboxmesh);
      mesh.geometry.scale(1,1,-1)
      
      mesh.scale.set(2,2,2);    //设置大小为原来的1.5倍
      mesh.traverse(function (child) {
        if (child.isMesh) {
            child.frustumCulled = false;
        }
    });
      scene.add(mesh);
      // mesh.rotation.x=MI
      //遍历子物体，如果是mesh就让他不裁剪消失

      
      // 初始化渲染器
      renderer = new Three.WebGLRenderer({antialias:true});
      // 渲染器的大小
      renderer.setSize(container.clientWidth,container.clientHeight);
      // 设置背景透明
      // 设置透明
      renderer.setClearAlpha(0.1)
      // 将渲染器添加到页面
      container.appendChild(renderer.domElement);

      // 加载模型
      // let that=this
     
      // 添加3d
       loader = new GLTFLoader();
    loader.load( 'static/glb/1111.glb', function ( glb ) {
      // glbe=glb.scence
      // console.log(glb.scene);
      glb.scene.scale.set(30,30,30)
      glb.scene.position.y = -100;
      // glb.scene.position.x = 0;
      glb.scene.position.z = -10;
      glb.scene.rotation.y=Math.PI
      // glb.scene.lookAt(camera.position)
      glb.scene.name= 'train'
    // console.log(glb)
      glb.scene.traverse( function ( child ) {
        if ( child.isMesh ) {
            child.frustumCulled = false;
            //模型阴影
            child.castShadow = true;
            //模型自发光
            child.material.emissive =  child.material.color;
            child.material.emissiveMap = child.material.map ;
        }})
        // let mixer = new Three.AnimationMixer(glb.scene);
        // console.log(mixer)
        animationMixer = new Three.AnimationMixer(glb.scene);
        clock = new Three.Clock();

        // 获取位置
        // 获取坐标
      var raycaster = new Three.Raycaster();//光线投射，用于确定鼠标点击位置
    var mouse = new Three.Vector3();//创建二维平面
    window.addEventListener("mouseup",mouseup);//页面绑定鼠标点击事件
    // //点击方法
    window.addEventListener("mousedown",mousedown);
    console.log(camera.rotation)
    cameraverar=camera.rotation
    function mousedown(e){
      console.log(e)
      console.log(camera.rotation)
      // this.cameraverar=camera.rotation
      //将html坐标系转化为webgl坐标系，并确定鼠标点击位置
      mouse.x =  e.clientX / renderer.domElement.clientWidth*2-1;
      mouse.y =  -(e.clientY / renderer.domElement.clientHeight*2)+1;
      //以camera为z坐标，确定所点击物体的3D空间位置
      raycaster.setFromCamera(mouse,camera);
      //确定所点击位置上的物体数量
       intersect = raycaster.intersectObjects(scene.children);
      // console.log(intersect[0])
    }
    function mouseup(e){
      console.log(camera.quaternion)
    //  let vectors = camera.getWorldDirection(vector);
    //  console.log(vector,vectors)
    // let angle = vector.angleTo( glb.scene.position );
    // console.log(angle)
    // // glb.scene.lookAt(theta)
    //   let a=angle-camera.rotation.y
    //   glb.scene.rotation.y=Math.PI*a
      // glb.scene.rotation.z=Math.PI*camera.rotation.z
      // glb.scene.rotation.x=Math.PI*camera.rotation.x
      // console.log(camera.position)
 
      
        //将html坐标系转化为webgl坐标系，并确定鼠标点击位置
        mouse.x =  e.clientX / renderer.domElement.clientWidth*2-1;
        mouse.y =  -(e.clientY / renderer.domElement.clientHeight*2)+1;
        //以camera为z坐标，确定所点击物体的3D空间位置
        raycaster.setFromCamera(mouse,camera);
        //确定所点击位置上的物体数量
        var intersects = raycaster.intersectObjects(scene.children);
        //选中后进行的操作
          // console.log(intersects[0].point.x)
        if(intersects[0].point.x!=intersect[0].point.x){
          console.log(camera.rotation,cameraverar)
          // let list= intersect[0].point.z-intersects[0].point.z
          // // console.log(intersects[0].point,intersect[0].point,list)
          // glb.scene.rotation.y= Math.PI*list
          if(amtion==null){glb.scene.lookAt(camera.position.x,-100,camera.position.z)}
          // console.log(action)
          // if(action!=null){
          //   // console.log('动画停止')
          //    action.stop()
          //   }
        }else {
          if(intersects.length){
          // console.log('本地坐标',mesh.position);
          console.log(mesh.up)
             var selected = intersects[0];//取第一个物体
            // console.log("x坐标:"+selected.point.x);
            // console.log("y坐标:"+selected.point.y);
            // console.log("z坐标:"+selected.point.z);
            let x=mesh.position.x-selected.point.x
            let z=mesh.position.z-selected.point.z
            var time=''
            let a=Math.abs(x)
            let b=Math.abs(z)
            let c=(a+b)/20
            console.log('时间：xz',a,b,c)
            console.log(selected.point,glb.scene.position)
            time=c
            if(amtion!=null){
              amtion.pause()
              // amtions.pause()
              // console.log('结束上个动画')
            }
            // if(x>1){
            //   if(z>1){
            //     time=(z/10+x/10)/2
            //     // console.log(time)
            //   }else {
            //     time=(z/-10+x/10)/2
            //     // console.log(time)
            //   }
            //   // console.log(time,'1')
            // }else {
            //   // console.log(x,z)
            //   if(z>1){
            //     time=(z/10+x/-10)/2
            //     // console.log(time)
            //     // console.log(time,z)
            //   }else {
            //     time=(z/-10+x/-10)/2
            //     // console.log(time)
            //     // console.log(z,time)
            //   }
            //   // time=x*-1/10
            // }
            console.log(x,z)
            if(selected.point.x<295&&selected.point.x>-295&&selected.point.z<360&&selected.point.z>-360&&selected.point.y<-90){
              // console.log('1')
              // 面向后面
              glb.scene.lookAt(selected.point.x,-100,selected.point.z)
              // glb.scene.rotation.y=Math.PI
              const animationClip = glb.animations.find(animationClip => animationClip.name ==="walk");
               action =  animationMixer.clipAction(animationClip);
               const animationClips = glb.animations.find(animationClip => animationClip.name ==="stop");
                          actions =  animationMixer.clipAction(animationClips);
               amtion =gsap.to(mesh.position, {
                        // 方向
                        x: x,
                        z:z,
                        // 时间
                        duration: time,
                        // 循环的次数
                        repeat: 0,
                        onStart() {
                          console.log('动画开始')
                          action.play();
                        },
                        // 动画结束
                        onComplete() {
                          glb.scene.lookAt(camera.position.x,-100,camera.position.z)
                          action.stop();
                          actions.play()
                          amtion=null
                        }
                })
            }
        }
        }
       
    }

      // 按键事件
    //   const animationClip = glb.animations.find(animationClip => animationClip.name ==="Running");
    // const action =  animationMixer.clipAction(animationClip);
    // action.play();
      document.onkeydown = function (event) {
        // console.log(event)
        //  var box = new Three.Box3().setFromObject( mesh );
         var glbfetZ=glb.scene.position.z
         var glbfetX=glb.scene.position.x
        //  var glbfetY=glb.scene.position.y
// var size = box.size()
// console.log(box,glb.scene.position)
console.log(event)
    if(event.key==='w'){
      // this.amtion.pause()
     
      // console.log(vector)
      // console.log()
      const animationClip = glb.animations.find(animationClip => animationClip.name ==="walk");
    const action =  animationMixer.clipAction(animationClip);
    action.play();
    glb.scene.rotation.y= Math.PI*2
    // if()
    // console.log(box.max.z)
    if(glbfetZ<360){
      
      glb.scene.position.z += 0.3;
      camera.position.z =camera.position.z +0.2
      // console.log(glb.scene.position)w
      // setTimeout(function(){
      //   action.stop()
      // },1000)
    }
      
      // action.stop()
    }else if(event.key==='a'){
      const animationClip = glb.animations.find(animationClip => animationClip.name ==="walk");
    const action =  animationMixer.clipAction(animationClip);
    action.play();
      glb.scene.rotation.y= Math.PI/2
      
      if(glbfetX<295){
      
        glb.scene.position.x += 0.5;
      camera.position.x =camera.position.x +0.5
    }
    }else if(event.key==='d'){
      const animationClip = glb.animations.find(animationClip => animationClip.name ==="walk");
    const action =  animationMixer.clipAction(animationClip);
    action.play();
      glb.scene.rotation.y= Math.PI*3.5
     
      if(glbfetX>-295){
      
        glb.scene.position.x -= 0.5;
      camera.position.x =camera.position.x -0.5
  }
    }else if(event.key==='s'){
      const animationClip = glb.animations.find(animationClip => animationClip.name ==="walk");
    const action =  animationMixer.clipAction(animationClip);
    action.play();
      glb.scene.rotation.y= Math.PI
      if(glbfetZ>-365){
        glb.scene.position.z -= 0.5;
      camera.position.z =camera.position.z -0.5
}
    }
  }
      
    scene.add(glb.scene);
    
    // 页面发生变化，监听页面变化
window.addEventListener('resize', () => {
  // 更新摄像头
  camera.aspect = window.innerWidth / window.innerHeight
  // 更新投影
  camera.updateProjectionMatrix();
  // 更新渲染器
  renderer.setSize(window.innerWidth, window.innerHeight)
  // 设置像素比
  renderer.setPixelRatio(window.devicePixelRatio)
})
    // console.log(action)
    }, undefined, function ( error ) {
      console.log( error );
    } );

      // 创建轨道控制器 相机围绕模型看到的角度
      const OrbitControl = new OrbitControls(camera, renderer.domElement)
      // 设置轨道自然
      OrbitControl.enableDamping = true
      // 设置惯性
      OrbitControl.update()
      console.log(OrbitControl)
      // 坐标轴
//       const axhelp = new Three.AxesHelper(300)
// scene.add(axhelp)
    // 设置地面平板
    // 
      
    },

    // // 模型移动
    // plane(){
      
    // },
    // 获取坐标
    animate(){
      // 浏览器自动渲染下一帧
      requestAnimationFrame(this.animate);
      // var animationMixer = new Three.AnimationMixer(scene);
      
      // 渲染到页面
      renderer.render(scene,camera); 
      if(animationMixer!==null){
    //clock.getDelta()方法获得两帧的时间间隔
    // 更新混合器相关的时间
    animationMixer.update(clock.getDelta());
  }
      // console.log(amtion)
      // animationMixer.update( clock.getDelta() )
    },
    sandapp(){
      this.$alert(' 医知医汇（北京）科技有限公司成立于2020年，公司位于北京市朝阳区。公司的主营业务包括：医疗学术会的策划和执行、线上虚拟展厅设计和制作、线上线下会议直播、医学课程录制、医学视频拍摄和制作、医学产品H5或视频展示等。 联系电话：010-67760432', '公司介绍', {
          confirmButtonText: '确定',
        })
    },
    // 跳转链接
    webapp(){
      console.log('跳转')
      window.location.href='https://beian.miit.gov.cn/'
    }
  },
  
  mounted(){
      this.init()
      this.animate()
      
      // this.plane()
  },
  created(){
    this.$nextTick(() => {
      // 禁用右键
      document.oncontextmenu = new Function('event.returnValue=false')
      // 禁用选择
      document.onselectstart = new Function('event.returnValue=false')
      //禁止f12
      document.onkeydown = new Function('event.returnValue=false')
      // document.onkeydown=function(event){
      //   console.log(event)
      // }
    })
  }

}

</script>
<style scoped>
#container{
    width: 100vw;
    height: 100vh;
    background-image: url('../../../public/static/image/8.jpg');
    -webkit-background-size: cover;
    background-size: cover;
}
.introduce {
  width: 100px;
  position: fixed;
  top: 20px;
  left: 0;
  background: hsla(0,0%,100%,.8);
  /* opacity: 0.8; */
  padding: 10px;
  font-size: 20px;
  font-weight: 600;
  border-radius: 10px ;
  text-align: center;
}
/* 下方介绍 */
.listapp {
  position: fixed;
bottom: 5px;
/* right: 20%; */
text-align: center;
width: 100vw;
font-size: 14px;
color: red;
font-weight: 600;
}
.todolist:hover {
  cursor:pointer;
}
</style>
