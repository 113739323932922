<template>
  <div>
    <!-- 按键 -->
    <div @click="clikeweb(item.id)"  ref="bth1">按键事件</div>
    <div @click="clikeweb"  ref="bth2">按键事件</div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      app:{
        color:''
      }
    }
  },
  methods:{
    clikeweb(e){
      console.log(e)
      this.app.color='red'
      this.$refs.bth1.style.color='red'
      console.log(this.$refs.bth1)
      console.log('这是按键事件')
    },
    // 页面加载之前运行事件
    creadweb(){
      console.log('页面加载之前执行')
    }
  },
  created(){
    this.creadweb()
  }
}
</script>
